import React from "react";
import * as classes from "./index.module.less";
import Layout from "components/shared/Layout";
import Card from "components/shared/Card";
import coding from "images/svg_icons/coding.svg";
import pc from "images/svg_icons/pc.svg";
import ecommerce from "images/svg_icons/mobile-shopping.svg";
import customer from "images/svg_icons/customer.svg";

export default () => (
  <div className={classes.container}>
    <Layout>
      <h3>Our Other Solutions</h3>
      <div className={classes.cards}>
        <Card
          title="eCommerce"
          href="/services/ecommerce/"
          align="verticalreverse"
          content={
            <p>
              Chinese consumers spent $1.9 trillion in retail eCommerce in 2019.
              We can help you enter and compete in this fast-growing B2C market.
            </p>
          }
          img={ecommerce}
        />
        <Card
          title="WeChat Mini Programs"
          href="/services/wechat-miniprograms/"
          align="verticalreverse"
          content={
            <p>
              Over 200 million people use WeChat Mini Programs, and AppInChina
              can develop one for you to further promote your brand in China.
            </p>
          }
          img={coding}
        />
        <Card
          title="SaaS"
          href="/services/other/saas/"
          align="verticalreverse"
          content={
            <p>
              From legal compliance to hosting, AppInChina can help you
              successfully launch and operate a Software as a Service (SaaS)
              product in China.
            </p>
          }
          img={pc}
        />
        <Card
          title="Customer Service"
          href="/services/customer-service/"
          align="verticalreverse"
          content={
            <p>
              Take care of all your Chinese customers' needs by setting up a
              Customer Support and Care plan through AppInChina.
            </p>
          }
          img={customer}
        />
      </div>
    </Layout>
  </div>
);
