import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import CTA from "components/shared/CTA";
import GreenAlert from "components/shared/GreenAlert";
import LargeTitle from "components/services/LargeTitle";
import icon from "images/svg_icons/support.svg";
import OtherServicesGrid from "components/services/OtherServicesGrid";

export default () => (
  <>
    <Meta url="/services/other/" />
    <Nav />
    <LargeTitle img={icon} green="Other" black="Services" />

    <GreenAlert>
      <p>
        In addition to our core offerings, we provide all the other services you
        need to expand your brand and run a successful web-based or app-based
        business in China.
      </p>
    </GreenAlert>
    <OtherServicesGrid />
    <CTA />
    <Footer />
  </>
);
